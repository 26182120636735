<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		x="0px"
		y="0px"
		viewBox="0 0 900 900"
		enable-background="new 0 0 900 900"
		xml:space="preserve"
	>
		<g>
			<path
				fill="#D1D3D4"
				d="M622.7,248.3c0-16.9-12.1-29.7-28.2-29.7c-13.4,0-24.2,8.7-28.2,22.4l-43.4,141.7l-42.5-136.2
		c-6.3-20.6-14.3-28.3-30.9-28.3c-14.3,0-23.3,8.2-29.1,28.3l-42.5,136.2l-43-141.7c-4.5-13.7-15.7-22.4-29.1-22.4
		c-16.1,0-28.2,12.8-28.2,29.7c0,4.1,0.9,7.8,2.2,11.4l63.6,181.9c7.2,20.1,18.4,29.7,34.9,29.7c15.7,0,25.1-8.7,31.3-27.4
		l40.8-127.1L491,444c5.8,18.7,16.1,27.4,30.9,27.4c17,0,28.2-9.6,35.4-29.7l63.6-181.9C622.2,256.1,622.7,252.5,622.7,248.3z"
			/>
			<path
				fill="#D1D3D4"
				d="M607.5,484.8c-7,0-8.7,1.3-15.7,4.8c-12.2,6.5-57.7,45.2-141.7,45.2c-84,0-129.4-38.8-141.7-45.2
		c-7-3.6-8.7-4.8-15.7-4.8c-10.5,0-19.2,8.1-19.2,17.8c0,8.1,4.2,11.6,7,14.5c14,14.5,70,59.8,169.7,59.8
		c99.7,0,155.7-45.3,169.7-59.8c2.8-2.9,7-6.5,7-14.5C626.7,492.9,618,484.8,607.5,484.8z"
			/>
		</g>
		<g>
			<path fill="#D1D3D4" d="M218.2,689.6v37.1h-7.8v-37.1H218.2z" />
			<path
				fill="#D1D3D4"
				d="M224.6,726.7v-27.8h7.4v4.9h0.3c0.6-1.6,1.5-2.9,2.9-3.9c1.4-0.9,3-1.4,4.9-1.4c1.9,0,3.5,0.5,4.9,1.4
		c1.3,0.9,2.2,2.2,2.7,3.8h0.3c0.6-1.6,1.6-2.9,3.1-3.8c1.5-1,3.3-1.4,5.3-1.4c2.6,0,4.7,0.8,6.3,2.5c1.6,1.6,2.4,4,2.4,7v18.7h-7.7
		v-17.2c0-1.5-0.4-2.7-1.2-3.5c-0.8-0.8-1.8-1.2-3.1-1.2c-1.4,0-2.5,0.4-3.3,1.3c-0.8,0.9-1.2,2.1-1.2,3.5v17H241v-17.4
		c0-1.4-0.4-2.5-1.2-3.3c-0.8-0.8-1.8-1.2-3.1-1.2c-0.9,0-1.6,0.2-2.3,0.6c-0.7,0.4-1.2,1-1.6,1.8c-0.4,0.8-0.6,1.7-0.6,2.7v16.7
		H224.6z"
			/>
			<path
				fill="#D1D3D4"
				d="M279,727.3c-1.8,0-3.4-0.3-4.7-0.9c-1.4-0.6-2.5-1.5-3.3-2.8c-0.8-1.2-1.2-2.8-1.2-4.6
		c0-1.5,0.3-2.8,0.9-3.9c0.6-1.1,1.3-1.9,2.3-2.5c1-0.6,2.1-1.1,3.3-1.5c1.3-0.3,2.6-0.6,3.9-0.7c1.6-0.2,2.9-0.3,3.9-0.5
		s1.7-0.4,2.2-0.7c0.4-0.3,0.7-0.7,0.7-1.3v-0.1c0-1.1-0.4-2-1.1-2.7c-0.7-0.6-1.7-0.9-3.1-0.9c-1.4,0-2.5,0.3-3.3,0.9
		c-0.8,0.6-1.4,1.4-1.6,2.3l-7.1-0.6c0.4-1.7,1.1-3.2,2.1-4.4c1.1-1.2,2.4-2.2,4.1-2.9c1.7-0.7,3.6-1,5.9-1c1.5,0,3,0.2,4.4,0.5
		c1.4,0.4,2.7,0.9,3.8,1.7c1.1,0.8,2,1.7,2.6,2.9c0.6,1.2,1,2.6,1,4.3v18.8h-7.3v-3.9h-0.2c-0.4,0.9-1,1.6-1.8,2.3
		c-0.7,0.7-1.6,1.2-2.7,1.5C281.6,727.1,280.4,727.3,279,727.3z M281.2,721.9c1.1,0,2.1-0.2,3-0.7c0.9-0.5,1.6-1.1,2-1.8
		c0.5-0.8,0.7-1.6,0.7-2.6v-3c-0.2,0.2-0.6,0.3-1,0.4c-0.4,0.1-0.9,0.2-1.4,0.3c-0.5,0.1-1,0.2-1.6,0.3c-0.5,0.1-1,0.1-1.4,0.2
		c-0.9,0.1-1.7,0.3-2.4,0.6c-0.7,0.3-1.2,0.7-1.6,1.2c-0.4,0.5-0.6,1.1-0.6,1.8c0,1.1,0.4,1.8,1.1,2.4
		C279.1,721.6,280,721.9,281.2,721.9z"
			/>
			<path
				fill="#D1D3D4"
				d="M313.2,737.7c-2.5,0-4.6-0.3-6.4-1c-1.8-0.7-3.2-1.6-4.2-2.8c-1.1-1.2-1.7-2.5-2-3.9l7.1-1
		c0.2,0.6,0.6,1.1,1,1.6c0.5,0.5,1.1,0.9,1.9,1.2c0.8,0.3,1.7,0.4,2.9,0.4c1.7,0,3.1-0.4,4.2-1.2c1.1-0.8,1.6-2.2,1.6-4.1v-5.1h-0.3
		c-0.3,0.8-0.8,1.5-1.5,2.2c-0.7,0.7-1.5,1.3-2.6,1.7c-1.1,0.4-2.3,0.7-3.8,0.7c-2.1,0-4-0.5-5.7-1.5c-1.7-1-3.1-2.5-4.1-4.5
		c-1-2-1.5-4.6-1.5-7.6c0-3.2,0.5-5.8,1.5-7.9c1-2.1,2.4-3.7,4.1-4.7c1.7-1.1,3.6-1.6,5.6-1.6c1.6,0,2.9,0.3,3.9,0.8
		c1.1,0.5,1.9,1.2,2.5,2c0.6,0.8,1.1,1.5,1.5,2.3h0.3v-4.7h7.7V727c0,2.4-0.6,4.3-1.7,5.9c-1.2,1.6-2.8,2.8-4.8,3.6
		C318.2,737.3,315.9,737.7,313.2,737.7z M313.4,720.5c1.2,0,2.3-0.3,3.2-0.9c0.9-0.6,1.5-1.5,2-2.7c0.5-1.2,0.7-2.6,0.7-4.2
		c0-1.6-0.2-3-0.7-4.2c-0.5-1.2-1.1-2.1-2-2.8c-0.9-0.7-1.9-1-3.2-1c-1.3,0-2.3,0.3-3.2,1c-0.9,0.7-1.5,1.6-2,2.8
		c-0.4,1.2-0.7,2.6-0.7,4.2c0,1.6,0.2,3,0.7,4.2c0.5,1.2,1.1,2.1,2,2.7C311,720.2,312.1,720.5,313.4,720.5z"
			/>
			<path
				fill="#D1D3D4"
				d="M345.7,727.3c-2.9,0-5.3-0.6-7.4-1.7c-2.1-1.2-3.6-2.8-4.8-5c-1.1-2.1-1.7-4.7-1.7-7.6
		c0-2.9,0.6-5.4,1.7-7.5c1.1-2.2,2.7-3.8,4.7-5.1c2-1.2,4.4-1.8,7.1-1.8c1.8,0,3.5,0.3,5.1,0.9c1.6,0.6,3,1.5,4.2,2.6
		c1.2,1.2,2.1,2.6,2.8,4.4c0.7,1.8,1,3.8,1,6.2v2.1h-23.5V710h16.2c0-1.1-0.2-2.1-0.7-3c-0.5-0.9-1.1-1.5-2-2
		c-0.9-0.5-1.8-0.7-3-0.7c-1.2,0-2.2,0.3-3.1,0.8c-0.9,0.5-1.6,1.3-2.1,2.2c-0.5,0.9-0.8,1.9-0.8,3v4.5c0,1.4,0.3,2.6,0.8,3.6
		c0.5,1,1.2,1.8,2.2,2.3c0.9,0.5,2.1,0.8,3.4,0.8c0.9,0,1.6-0.1,2.4-0.4c0.7-0.2,1.3-0.6,1.8-1.1c0.5-0.5,0.9-1.1,1.2-1.8l7.1,0.5
		c-0.4,1.7-1.1,3.2-2.2,4.5c-1.1,1.3-2.6,2.3-4.3,3C350,726.9,347.9,727.3,345.7,727.3z"
			/>
			<path
				fill="#D1D3D4"
				d="M387.9,727.3c-2.8,0-5.3-0.6-7.3-1.8c-2-1.2-3.6-2.9-4.7-5.1s-1.6-4.6-1.6-7.5c0-2.9,0.6-5.4,1.7-7.5
		c1.1-2.2,2.7-3.8,4.7-5.1c2-1.2,4.5-1.8,7.3-1.8c2.4,0,4.6,0.4,6.4,1.3c1.8,0.9,3.3,2.1,4.3,3.7c1.1,1.6,1.6,3.5,1.8,5.6H393
		c-0.2-1.4-0.7-2.5-1.6-3.4c-0.9-0.9-2-1.3-3.4-1.3c-1.2,0-2.2,0.3-3.1,1s-1.6,1.6-2.1,2.8c-0.5,1.2-0.7,2.7-0.7,4.5
		c0,1.8,0.2,3.3,0.7,4.5c0.5,1.2,1.2,2.2,2.1,2.8c0.9,0.7,1.9,1,3.1,1c0.9,0,1.7-0.2,2.4-0.5c0.7-0.4,1.3-0.9,1.8-1.6
		c0.5-0.7,0.8-1.5,0.9-2.5h7.3c-0.1,2.1-0.7,4-1.7,5.6c-1,1.6-2.5,2.9-4.3,3.8C392.5,726.8,390.3,727.3,387.9,727.3z"
			/>
			<path
				fill="#D1D3D4"
				d="M417.8,727.3c-2.8,0-5.2-0.6-7.3-1.8c-2-1.2-3.6-2.9-4.7-5c-1.1-2.2-1.7-4.7-1.7-7.5c0-2.9,0.6-5.4,1.7-7.5
		c1.1-2.2,2.7-3.8,4.7-5c2-1.2,4.5-1.8,7.3-1.8c2.8,0,5.2,0.6,7.3,1.8c2,1.2,3.6,2.9,4.7,5c1.1,2.2,1.7,4.7,1.7,7.5
		c0,2.8-0.6,5.4-1.7,7.5c-1.1,2.2-2.7,3.8-4.7,5C423.1,726.7,420.6,727.3,417.8,727.3z M417.9,721.3c1.3,0,2.3-0.4,3.2-1.1
		c0.9-0.7,1.5-1.7,1.9-3c0.4-1.3,0.7-2.7,0.7-4.3c0-1.6-0.2-3.1-0.7-4.3c-0.4-1.3-1.1-2.3-1.9-3c-0.9-0.7-1.9-1.1-3.2-1.1
		c-1.3,0-2.4,0.4-3.3,1.1c-0.9,0.7-1.5,1.7-2,3c-0.4,1.3-0.7,2.7-0.7,4.3c0,1.6,0.2,3.1,0.7,4.3c0.4,1.3,1.1,2.3,2,3
		C415.5,720.9,416.6,721.3,417.9,721.3z"
			/>
			<path
				fill="#D1D3D4"
				d="M436.5,726.7v-27.8h7.4v4.9h0.3c0.6-1.6,1.5-2.9,2.9-3.9c1.4-0.9,3-1.4,4.9-1.4c1.9,0,3.5,0.5,4.9,1.4
		c1.3,0.9,2.2,2.2,2.7,3.8h0.3c0.6-1.6,1.6-2.9,3.1-3.8c1.5-1,3.3-1.4,5.3-1.4c2.6,0,4.7,0.8,6.3,2.5c1.6,1.6,2.4,4,2.4,7v18.7h-7.7
		v-17.2c0-1.5-0.4-2.7-1.2-3.5c-0.8-0.8-1.8-1.2-3.1-1.2c-1.4,0-2.5,0.4-3.3,1.3c-0.8,0.9-1.2,2.1-1.2,3.5v17H453v-17.4
		c0-1.4-0.4-2.5-1.2-3.3c-0.8-0.8-1.8-1.2-3.1-1.2c-0.9,0-1.6,0.2-2.3,0.6c-0.7,0.4-1.2,1-1.6,1.8c-0.4,0.8-0.6,1.7-0.6,2.7v16.7
		H436.5z"
			/>
			<path
				fill="#D1D3D4"
				d="M486.9,695.3c-1.1,0-2.1-0.4-2.9-1.1c-0.8-0.8-1.2-1.7-1.2-2.8c0-1.1,0.4-2,1.2-2.7
		c0.8-0.8,1.8-1.1,2.9-1.1c1.1,0,2.1,0.4,2.9,1.1c0.8,0.8,1.2,1.7,1.2,2.7c0,1.1-0.4,2-1.2,2.8C489.1,694.9,488.1,695.3,486.9,695.3
		z M483.1,726.7v-27.8h7.7v27.8H483.1z"
			/>
			<path
				fill="#D1D3D4"
				d="M504.7,710.6v16.1h-7.7v-27.8h7.4v4.9h0.3c0.6-1.6,1.6-2.9,3.1-3.8c1.5-0.9,3.2-1.4,5.3-1.4
		c1.9,0,3.6,0.4,5.1,1.3c1.4,0.8,2.6,2,3.4,3.6c0.8,1.6,1.2,3.4,1.2,5.6v17.7h-7.7v-16.3c0-1.7-0.4-3-1.3-4c-0.9-1-2.1-1.4-3.6-1.4
		c-1,0-2,0.2-2.7,0.7c-0.8,0.4-1.4,1.1-1.8,1.9C504.9,708.4,504.7,709.4,504.7,710.6z"
			/>
			<path
				fill="#D1D3D4"
				d="M541.2,737.7c-2.5,0-4.6-0.3-6.4-1c-1.8-0.7-3.2-1.6-4.2-2.8c-1.1-1.2-1.7-2.5-2-3.9l7.1-1
		c0.2,0.6,0.6,1.1,1,1.6c0.5,0.5,1.1,0.9,1.9,1.2c0.8,0.3,1.7,0.4,2.9,0.4c1.7,0,3.1-0.4,4.2-1.2c1.1-0.8,1.6-2.2,1.6-4.1v-5.1h-0.3
		c-0.3,0.8-0.8,1.5-1.5,2.2c-0.7,0.7-1.5,1.3-2.6,1.7c-1.1,0.4-2.3,0.7-3.8,0.7c-2.1,0-4-0.5-5.7-1.5c-1.7-1-3.1-2.5-4.1-4.5
		c-1-2-1.5-4.6-1.5-7.6c0-3.2,0.5-5.8,1.5-7.9c1-2.1,2.4-3.7,4.1-4.7c1.7-1.1,3.6-1.6,5.6-1.6c1.6,0,2.9,0.3,3.9,0.8
		c1,0.5,1.9,1.2,2.5,2c0.6,0.8,1.1,1.5,1.5,2.3h0.3v-4.7h7.7V727c0,2.4-0.6,4.3-1.7,5.9c-1.2,1.6-2.8,2.8-4.8,3.6
		C546.2,737.3,543.8,737.7,541.2,737.7z M541.4,720.5c1.2,0,2.3-0.3,3.2-0.9c0.9-0.6,1.5-1.5,2-2.7c0.5-1.2,0.7-2.6,0.7-4.2
		c0-1.6-0.2-3-0.7-4.2c-0.5-1.2-1.1-2.1-2-2.8c-0.9-0.7-1.9-1-3.2-1c-1.3,0-2.3,0.3-3.2,1c-0.9,0.7-1.5,1.6-2,2.8
		c-0.4,1.2-0.7,2.6-0.7,4.2c0,1.6,0.2,3,0.7,4.2c0.5,1.2,1.1,2.1,2,2.7C539,720.2,540.1,720.5,541.4,720.5z"
			/>
			<path
				fill="#D1D3D4"
				d="M595.9,706.8l-7.1,0.4c-0.1-0.6-0.4-1.1-0.8-1.6c-0.4-0.5-0.9-0.9-1.6-1.2c-0.6-0.3-1.4-0.4-2.3-0.4
		c-1.2,0-2.2,0.3-3,0.8c-0.8,0.5-1.2,1.2-1.2,2c0,0.7,0.3,1.2,0.8,1.7c0.5,0.5,1.4,0.8,2.7,1.1l5,1c2.7,0.6,4.7,1.4,6.1,2.7
		c1.3,1.2,2,2.9,2,4.9c0,1.8-0.5,3.4-1.6,4.8c-1.1,1.4-2.5,2.4-4.4,3.2c-1.9,0.8-4,1.2-6.4,1.2c-3.7,0-6.6-0.8-8.8-2.3
		c-2.2-1.5-3.5-3.6-3.8-6.3l7.6-0.4c0.2,1.1,0.8,2,1.7,2.6c0.9,0.6,2,0.9,3.4,0.9c1.4,0,2.4-0.3,3.3-0.8c0.8-0.5,1.2-1.2,1.3-2
		c0-0.7-0.3-1.3-0.9-1.7c-0.6-0.5-1.5-0.8-2.7-1l-4.8-1c-2.7-0.5-4.7-1.5-6.1-2.8c-1.3-1.3-2-3-2-5.1c0-1.8,0.5-3.3,1.5-4.6
		c1-1.3,2.3-2.3,4.1-3c1.8-0.7,3.8-1.1,6.2-1.1c3.5,0,6.3,0.7,8.3,2.2C594.4,702.3,595.6,704.3,595.9,706.8z"
			/>
			<path
				fill="#D1D3D4"
				d="M614,727.3c-2.8,0-5.2-0.6-7.3-1.8c-2-1.2-3.6-2.9-4.7-5c-1.1-2.2-1.7-4.7-1.7-7.5c0-2.9,0.6-5.4,1.7-7.5
		c1.1-2.2,2.7-3.8,4.7-5c2-1.2,4.5-1.8,7.3-1.8c2.8,0,5.2,0.6,7.3,1.8c2,1.2,3.6,2.9,4.7,5c1.1,2.2,1.7,4.7,1.7,7.5
		c0,2.8-0.6,5.4-1.7,7.5c-1.1,2.2-2.7,3.8-4.7,5C619.2,726.7,616.8,727.3,614,727.3z M614,721.3c1.3,0,2.3-0.4,3.2-1.1
		c0.9-0.7,1.5-1.7,1.9-3c0.4-1.3,0.7-2.7,0.7-4.3c0-1.6-0.2-3.1-0.7-4.3c-0.4-1.3-1.1-2.3-1.9-3c-0.9-0.7-1.9-1.1-3.2-1.1
		c-1.3,0-2.4,0.4-3.3,1.1c-0.9,0.7-1.5,1.7-2,3c-0.4,1.3-0.7,2.7-0.7,4.3c0,1.6,0.2,3.1,0.7,4.3c0.4,1.3,1.1,2.3,2,3
		C611.7,720.9,612.8,721.3,614,721.3z"
			/>
			<path
				fill="#D1D3D4"
				d="M645.3,727.3c-2.8,0-5.2-0.6-7.3-1.8c-2-1.2-3.6-2.9-4.7-5c-1.1-2.2-1.7-4.7-1.7-7.5c0-2.9,0.6-5.4,1.7-7.5
		c1.1-2.2,2.7-3.8,4.7-5c2-1.2,4.5-1.8,7.3-1.8c2.8,0,5.2,0.6,7.3,1.8c2,1.2,3.6,2.9,4.7,5c1.1,2.2,1.7,4.7,1.7,7.5
		c0,2.8-0.6,5.4-1.7,7.5c-1.1,2.2-2.7,3.8-4.7,5C650.6,726.7,648.1,727.3,645.3,727.3z M645.3,721.3c1.3,0,2.3-0.4,3.2-1.1
		c0.9-0.7,1.5-1.7,1.9-3c0.4-1.3,0.7-2.7,0.7-4.3c0-1.6-0.2-3.1-0.7-4.3c-0.4-1.3-1.1-2.3-1.9-3c-0.9-0.7-1.9-1.1-3.2-1.1
		c-1.3,0-2.4,0.4-3.3,1.1c-0.9,0.7-1.5,1.7-2,3c-0.4,1.3-0.7,2.7-0.7,4.3c0,1.6,0.2,3.1,0.7,4.3c0.4,1.3,1.1,2.3,2,3
		C643,720.9,644.1,721.3,645.3,721.3z"
			/>
			<path
				fill="#D1D3D4"
				d="M671.8,710.6v16.1H664v-27.8h7.4v4.9h0.3c0.6-1.6,1.6-2.9,3.1-3.8c1.4-0.9,3.2-1.4,5.3-1.4
		c1.9,0,3.6,0.4,5.1,1.3c1.4,0.8,2.6,2,3.4,3.6c0.8,1.6,1.2,3.4,1.2,5.6v17.7H682v-16.3c0-1.7-0.4-3-1.3-4c-0.9-1-2.1-1.4-3.6-1.4
		c-1,0-2,0.2-2.7,0.7c-0.8,0.4-1.4,1.1-1.8,1.9C672,708.4,671.8,709.4,671.8,710.6z"
			/>
		</g>
	</svg>
</template>
